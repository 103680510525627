.LoadingAnimation {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 920px) {
		height: 70px;
	}
}

.LoadingAnimation svg {
    width: 100%;
    height: 100%;
    visibility: hidden; 
}