@import '../../app/theme.scss';

.TMKFrontentd {
  width: 100%;
  padding: 32px 44px;
  font-family: 'Ubuntu', sans-serif !important;
}

.TMKFrontentdMobile {
  @media (max-width: 920px) {
    display: none;
    margin-bottom: 200px;
  }
}
