.NoteWrapper {
	display: inline-block;
	flex-direction: column;
	gap: 10px;
	@media (max-width: 920px) {
		padding: 20px;
		gap: 0px;
	}
}

.Title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	text-align: center;

	@media (max-width: 920px) {
		text-align: center;
		font-family: "Inter", sans-serif;
		line-height: 18px;
		font-size: 12px;
	}
}

.NoteParagraph {
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	max-width: 400px;

	@media (max-width: 920px) {
		font-size: 13px;
		line-height: 18px;

		.NoteParagraph:nth-child(2) {
			white-space: nowrap;
		}
	}
}

.NoteParagraphStart {
	font-weight: 600;
	padding: 0px;
	margin: 0px;
	@media (max-width: 920px) {
		white-space: nowrap;
	}
}

.CloseButtonContainer {
	width: 100%;
	display: flex;
	justify-content: center;
}

.CloseButton {
	width: 220px;
	font-size: 14px;
	font-family: Inter,sans-serif;
}

