@import "../styles//mixins.scss";
@import "../app/theme.scss";

.alert-danger {
	color: rgb(249, 2, 2);
}
p {
	color: white;
}
.btn-group {
	background-color: red;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

#controls {
	width: 100px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;

	button {
		float: none;
	}
}

.panel-heading {
	background-color: #f2f2f2;
	padding: 10px;
	font-size: 20px;
	text-align: center;
}
@import "src/app/theme.scss";
.DisabledButton.DisabledButton {
	:global(.ant-typography) {
		color: $textGrey;
	}
}
.DisabledButtonText.DisabledButtonText {
	color: $textGrey !important;
}


.PrimalButton {
	border-radius: 10px;
	background: $primary;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;

	:global(.ant-typography) {
		color: $white;
	}

	&:hover {
		background-color: rgba($primary, 0.08);
		border: 1px solid;

		:global(.ant-typography) {
			color: $primary;
		}
	}

	&:disabled {
		background-color: $disbledButton;
		:global(.ant-typography) {
			color: $disbledButtonText;
		}
	}
}

.SecondaryButton {
	border-radius: 10px;
	background: $background;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $primary;
	font-size: 16px;
	border: none;

	:global(.ant-typography) {
		color: $primary;
	}
}

.SecondaryButton:hover {
	background-color: $hoveredBackground;
}

.MessageContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	width: 100%;
}

.MessageRow {
	flex-wrap: nowrap;
	gap: 10px;
	border: 1px solid $border;
	border-radius: 4px 10px 10px 10px;
	padding: 14px;
	box-shadow: 0 3px 5px rgba($black, 0.05);
	background-color: $white;
	margin-left: 10px;
	width: 100%;

	@include breakpoint(desktop) {
		padding: 30px;
	}
}

.TitleRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	flex-wrap: nowrap;
}

.Title {
	font-size: 14px;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	@include breakpoint(desktop) {
		font-size: 16px;
	}
}

.TextCol {
	width: 100%;
}

.Text {
	font-size: 14px;
	font-weight: 400;
	padding: 10px 0;

	span {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: $primary;
		white-space: nowrap;
	}
}

.ViewedText {
	color: $textGrey;
}

.TextLink.TextLink {
	color: $primary;
}

.NotificationsWrapper {
	width: 386px;
	min-width: 386px;
	gap: 14px;
	height: inherit;

	@media (max-width: 920px) {
		width: 100%;
		min-width: 100%;
	}
}

.TimeWithMarkContainer {
	align-items: center;
	width: 100%;

	svg {
		margin-left: 10px;
	}
}

.MessageTag {
	align-self: flex-end;
	white-space: nowrap;
}

.NotificationsTabNumber {
	background: $primary;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-left: 8px;
	justify-content: center;
	align-items: center;

	span {
		color: $white;
		font-size: 10px;
	}
}

.NotificationsTabNumberInactive {
	background: $greyBackground;
}

.HiddenLabel {
	display: none;
}

.ScrollableDiv {
	display: flex;
	flex-direction: column;
	gap: 14px;
}

.Chats {
	opacity: 1;
	position: relative;
	z-index: 1;
	width: 100%;
	height: inherit;

	&.ShowChats {
		position: relative;
		opacity: 100;
		z-index: 1;
	}

	& :global {
		.chat-container.chat-container {
			border-radius: 14px;
			background: #fff;
			box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
			overflow: hidden;
			font-family: "Inter", sans-serif;
			color: $textPrimary;

			& svg path {
				fill: $chatsSvg;
			}

			& svg.chat-settings circle {
				fill: $primary;
			}

			& .chat-send-btn {
				display: inline-block;
				position: relative;
				cursor: pointer;
			}

			& .chat-send-btn svg path {
				fill: $primary !important;
			}

			& .chat-send-btn.chat-send-btn_disabled svg path {
				fill: $chatsSvg;
			}

			& .message-box-main-container {
				max-width: 336px;
				flex-direction: row;
				justify-content: flex-end;
			}

			& .message-box-text {
				line-height: 18px !important;
				background: #ffffff !important;
				border: none !important;
				border-radius: 2px 6px 6px 6px;
				padding: 17px 17px 30px 17px;
				width: fit-content;
			}

			& .message-wrapper.reverse .message-box-text {
				border-radius: 6px 2px 6px 6px;
				background: #e9eaf0 !important;
				border: 1px solid #dadbe1 !important;
				color: $textPrimary;
				padding: 17px 17px 30px 17px;
				width: fit-content;
			}

			& .message-box-settings-container .message-box-settings-item:not(:first-child) {
				display: none;
			}

			& .message-box-settings-container .message-box-settings-item {
				margin-bottom: 0;
			}

			& .message-box-settings-container .message-box-settings-item:hover {
				color: $primary;

				& svg path {
					fill: $primary;
				}
			}

			& .message-box-settings-item.outer-click-btn {
				&:first-child {
					color: $primary;
				}

				&:last-child {
					color: $red;
				}
			}

			& .message-read-icon {
				top: 24px;
				right: 20px;
			}

			& .message-read-icon_merge-messages {
				top: 8px !important;
				right: 20px;
			}

			& .message-read-icon svg {
				width: 7.2px;
			}

			& .message-box-text {
				border: none;
				font-size: 14px;
				line-height: 16px;
				background: #ffffff;
			}

			& .message-box-text .message-box-time {
				bottom: 10px;
				right: auto;
				top: auto;
				font-size: 10px;
			}

			& .message-box-text::after {
				display: none;
			}

			& .message-box-text::before {
				display: none;
			}

			& .message-box-author {
				font-size: 16px;
				color: $primary;
			}

			& .message-attachment {
				color: #15181f;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px;
			}

			& .chat-header {
				background-color: #f0f2f8;
			}

			& .chat-wrapper {
				display: flex;
				flex-direction: column;
				background-color: #f0f2f8;
			}

			& .chat-list {
				max-height: 500px;
			}

			& .chat-date {
				border-radius: 14px;
				padding: 2px 10px;
				border: 1px solid #d9d9d9;
				margin: 0 auto 8px;
				color: #15181f;
			}

			& .chat-input-wrapper {
				padding: 3px 10px;
			}

			& .chat-input-container {
				align-items: center;
			}

			& .chat-input-container,
			.chat-item_selected,
			.chat-item:hover {
				background-color: white;
			}

			& .chat-input {
				height: 26px;
			}

			& .chat-item__name {
				font-size: 14px;
			}

			& .chat-item__user-name {
				font-size: 12px;
			}

			& .chat-item__name,
			.chat-item__user-name {
				color: $textPrimary;
				margin-bottom: 4px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			& .chat-item__text {
				font-size: 12px;
				color: $textGrey;
			}
		}

		.chat-name.chat-name {
			font-size: 11px;
			font-weight: 700;
			text-transform: uppercase;
			color: $textPrimary;

			& svg {
				display: none;
			}
		}
	}
}

.ChatsList {
	margin-top: 20px;
}

.Image {
	max-width: 217px;
}

.StubContainer {
	width: 100%;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.alert-danger {
	color: rgb(249, 2, 2);
}
p {
	color: white;
}

video {
	padding: 10px;
	// width: 50%;
	box-sizing: border-box;
}

.panel-heading {
	background-color: #f2f2f2;
	padding: 10px;
	font-size: 20px;
	text-align: center;
}

.HiddenOnMobile {
	@media (max-width: 920px) {
		display: none;
	}
}

.NotOnlineNote {
	text-align: center;
	padding: 20px;
	line-height: 16px;
}

.devices_info_text {
	text-align: center !important;
}

