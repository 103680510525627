#root {
	width: 100%;
	height: 100vh;
	background-color: #2c3135;
}
html,
body {
	width: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	background-color: rgb(144, 143, 143);
}
video {
	border-radius: 12px;
}
.container {
	display: flex;
	justify-content: space-between;
	padding: 15px;
	gap: 20px;
	background-color: #2c3135;
	width: 100%;
	box-sizing: border-box;

	font: 16px/1 "Helvetica Neue", Helvetica, Arial, sans-serif;

	@media (min-width: 960px) {
		height: 100%;
	}

	@media (max-width: 960px) {
		flex-direction: column;
	}
}

div.panel-body {
	display: flex;
	flex-direction: column;
}

.btn-group {
	gap: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
button {
	width: 50px;
	height: 50px;
	font-family: "Inter", sans-serif;
}
button:has(.call_btn) {
	height: 50px;
	width: max-content;
	font-family: "Inter", sans-serif;
	color: white;
}
button:has(.call_btn_disabled) {
	height: 50px;
	width: max-content;
	font-family: "Inter", sans-serif;
	color: #767886 !important;
}
#controls {
	display: flex;
	flex-direction: row;

	gap: 15px;
	top: 500px;
	& > button:first-child {
		margin-left: 2px;
	}
}

.hang_call {
	display: flex;
	margin-right: 0;
	order: 1;
	top: 500px;
}
.call_to {
	display: flex;
	order: 1;
	color: white;
}
#voximplant_container_wrap {
	display: flex;
	flex-direction: row;
}
#voximplant_local {
	max-width: 233px;
	width: 100%;
	height: 174px;
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	box-sizing: border-box;
}
#voximplant_local {
	position: relative;
	justify-content: flex-end;
	align-items: center;

	@media (max-width: 920px) {
		position: absolute;
		width: 90px;
		height: 90px;
		z-index: 20;
		bottom: -110px;
		right: 5px;
	}
}

#voximplant_local video {
	display: flex;
	border-radius: 20px;
	width: 100%;
	height: auto;
	padding: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	object-fit: cover;
	z-index: 0;
	@media (max-width: 920px) {
		border-radius: 10px;
	}
}

#voximplant_local .initials_local {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	z-index: 1;
	width: fit-content;
	@media (max-width: 920px) {
		display: none;
	}
}

#voximplant_container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;

	gap: 15px;
	box-sizing: border-box;

	@media (max-width: 920px) {
		flex-direction: column;
	}
}
.voximplant_wrapper {
	display: flex;
	width: 100%;
}

.column,
.second_column {
	display: flex;
	flex-direction: column;
	gap: 15px;
	justify-content: flex-end;
	align-items: center;
	width: 232px;
}
.second_column {
	width: 100%;
}

.column {
	@media (max-width: 920px) {
		width: 100%;
		position: relative;
	}
}

.patientVideo {
	display: flex;
	flex-direction: column;
}

.controls_wrapper {
	height: 50px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	order: 2;
	gap: 10px;
}

.controls_wrapper:has(button) p:has(.call_btn) {
	display: flex;
	justify-content: center;
	align-items: center;
}
.controls_wrapper:has(button) p:has(.call_btn_disabled) {
	display: flex;
	justify-content: center;
	align-items: center;
}

.initials_remote .initials_micro {
	width: 14px;
	height: 14px;
}
.initials_remote .initials_micro_disable {
	width: 14px;
	height: 14px;
}

#voximplant_remote {
	border-radius: 20px;
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
	position: relative;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
	flex: auto;
	@media (max-width: 920px) {
		height: 100%;
	}
}
#voximplant_remote .empty {
	@media (max-width: 920px) {
		height: 347px;
		z-index: 0 !important;
	}
}

#voximplant_remote .empty_patient {
	@media (max-width: 920px) {
		height: 397px;
		z-index: 0 !important;
	}
}

#voximplant_remote video {
	padding: 0;

	border-radius: 12px;
	width: 100%;
	height: 100%;
	position: absolute;
	object-fit: cover;
	z-index: 0;
	@media (max-width: 920px) {
		height: 347px;
	}
}
#voximplant_remote .initials_remote {
	z-index: 10;
	position: absolute;
}

.input_group {
	top: 500px;
}

#voximplant_local:has(video) .empty {
	display: none;
}
.meeting_info {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 16px;
	flex: auto;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 12px;

	@media (min-width: 920px) {
		max-width: 233px;
	}

	@media (max-width: 920px) {
		box-sizing: border-box;
		width: 100%;
	}
}
.entry_start {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	padding: 0px, 14px, 0px, 14px;
	background-color: #a6c763;
	border-radius: 20px;
	font-size: 11px;
	width: 149px;
	font-weight: 700;
}
.entry_name {
	font-size: 18px;
	font-weight: 600;
}
.entry_name,
.entry_date_time {
	color: white;
}
.entry_date_time {
	font-size: 14px;
	font-weight: 400;
	opacity: 0.6;
}

.back-button {
	display: block;
	border: 1px solid #e1e5ed99;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	padding: 0px, 14px;
	background-color: transparent;
	border-radius: 20px;
	font-size: 11px;
	width: 85px;
	font-weight: 700;
	color: #e1e5ed99;
	gap: 10px;
	text-transform: uppercase;
}

.back_icon {
	color: #e1e5ed99;
}

.entry_wrapper {
	display: flex;
	gap: 10px;
}

.empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #66707b;
	border-radius: 12px;
	gap: 30px;
	.devices_info{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 251px;
		width: 80%;
	.danger_icon {
		width: 100px;
		height: 100px;
	}
	.no_calls {
		font-weight: bold ;
		text-align: center;
		font-size: 18px;
		font-family: "Inter", sans-serif;
		line-height: 24px;
	}
	.devices_info_text {
		text-align: center;
		font-family: "Inter", sans-serif;
		line-height: 24px;
	}

}
}
@media (max-width: 768px) {
    .empty {
		
        .devices_info {
            height: 178px;
            width: 80%;
			position:relative;
			top: -20px;
			.devices_info_text, .no_calls {
				text-align: center;
				font-family: "Inter", sans-serif;
				line-height: 18px;
				font-size: 12px;
			}
			.danger_icon {
				width: 50px;
				height: 50px;
			}
        }
		
	
    }
}
.loading_wrapper {
	flex-direction: column-reverse;

	width: 100%;
	gap: 20px;
	order: 3;
}
.loading {
	font-weight: bold;
	display: inline-block;
	font-family: monospace;
	font-size: 30px;
	color: white;
	clip-path: inset(0 3ch 0 0);
	animation: l 1s steps(4) infinite;
	margin: 0;
	text-align: center;
}
.waiting_line {
	color: white;

	text-align: center;
}
.person_circle,
.person_circle_local {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid none;
	border-radius: 50%;
	background: #434b51;
}
.person_circle img {
	height: 75px;
	width: 60px;
}
.person_circle_local {
	height: 90px;
	width: 90px;
}
.person_circle_local img {
	height: 27px;
	width: 22px;
}
.person_circle {
	height: 300px;
	width: 300px;
}

.initials {
	display: flex;
	flex-direction: row;
	color: black;
	background-color: #d9dbe5e5;
	padding: 10px;
	top: 460px;
	height: 26px;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}
.initials_local,
.initials_remote {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	color: black;
	background-color: #d9dbe5e5;
	padding: 0px 10px;
	height: 26px;
	border-radius: 20px;
	font-size: 14px;
	font-weight: 500;
	align-items: center;
	bottom: 15px;
	width: fit-content;

	@media (max-width: 920px) {
		z-index: 1;
		position: absolute;
	}
}
.initials_local_text,
.initials_remote_text {
	width: 155px;
	margin-right: 15px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@keyframes l {
	to {
		clip-path: inset(0 -1ch 0 0);
	}
}
.duration,
.duration_mobile {
	background: #0000004d;
	border-radius: 10px;
	width: 233px;
	height: 50px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 920px) {
		display: none;
	}
}

.duration_mobile {
	display: none;
	@media (max-width: 920px) {
		display: flex;
	}
}
#log {
	margin: 20px;
	text-align: left;
	font-size: 70%;
	font-family: Monospace;
}

.vertical-center {
	height: 100%;
	width: 100%;
	text-align: center;
	font: 0/0 a;

	&:before {
		content: " ";
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}
}
