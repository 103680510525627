$primary: #7f49de;
$menuHover: #6c3ebd;
$white: #ffffff;
$black: #000000;
$primaryDark: #1f2c84;
$primaryPale: #8992da;
$textPrimary: #15181f;
$textPale: #8d91c0;
$textGrey: #767886;
$background: #f0f2f8;
$backgroundAvatar: #d9dbe5;
$lightBlue: #c5d2de;
$border: #e7e9ef;
$green: #a6c763;
$connectButton: #e79e44;
$disabledInput: #f6f6f6;
$disabledInputColor: #444444;
$inputBorder: #e0e6eb;
$buttonBorder: #b9c5d0;
$orange: #e79e44;
$hoveredBackground: #f8f9fc;
$red: #fa6060;
$greyBorder: #bcc2ca;
$greyBackground: #979797;
$greySliderDot: #c2c9cf;
$darkGreyBorder: #dadbe1;
$disabledSwitchBg: #c4c4c4;
$disbledButton: #d7d7d7;
$disbledButtonText: #8d8d8d;
$chatsSvg: #292d32;
$divider: #e1e5ed;
