@import "../../../styles/mixins.scss";
@import "../../../app/theme.scss";

.MobileChat {
    background-color: $background;
    border-radius: 12px;
    height: 55px;
    width: 100%;
    display: none;

    @media (max-width: 920px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.OpenChatBtn {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
